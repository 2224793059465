export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) return

  const orderStore = useOrder()
  const { shipping, delivery } = orderStore

  if (shipping && delivery) return

  const user = useAuth().data.value!

  return navigateTo(
    useLocalePath()(
      user.type === 'guest' ? '/checkout/guest' : '/checkout/shipping',
    ),
  )
})
